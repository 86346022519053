<template>
  <div class="calendar">
    <img
      src="@/assets/icon-calendar.png"/>
    <v-date-picker
      :popover="popover"
      :model-config="modelConfig"
      :min-date="min"
      :max-date="max"      
      class="calendar-div"
      v-model="date">
      <template
        v-slot="{ inputValue, inputEvents }">
        <input :value="inputValue"
          class="form-control calendar-input"
          v-on="inputEvents"
          :placeholder="placeholder" />
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import 'v-calendar/dist/style.css';

export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    max: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },    
  },
  data() {
    return {
      visible: true,
      popover: {visibility: 'click'},
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
    }
  },
  computed: {
    date: {
      get () {
        return this.dateToString(this.value)
      },
      set (newVal) {
        console.log('newVal:' + newVal)              
        if (this.value !== newVal) this.$emit('input', this.dateToString(newVal))
      },
    },
  },
  methods: {
    dateToString(date) {
      // バージョンによって、objectの形が変わるので、githubのactionに合わせて、強制的にstringにcastしている
      if (date instanceof Date) {
        const year = date.getFullYear()
        const month = ('0' + (date.getMonth() + 1)).slice(-2)
        const day = ('0' + date.getDate()).slice(-2)
        return `${year}-${month}-${day}`
      }
      return date
    },
    inputEvents(v) {
      console.log('inputEvents')
      this.$emit('input', v)
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar {
  display: flex;
  align-items: center;
  width: 280px;
  img {
    z-index: 999;
    margin-left: 6px;
    width: 40px;
    position: absolute;
  }
  .calendar-div {
    position: absolute;
  }
  .calendar-input {
    font-size: 28px;
    padding-left: 50px;
    width: 215px;
    height: 45px;
  }
}
</style>