<template>
  <div>
    <div class="header">
      <div
        @click="backToList"
        class="btn-img">
        <img src="@/assets/icon-back.png"/>
      </div>
    </div>
    <div class="page-title">
      {{pageTitle}}
    </div>
    <div class="search-calendar">
      <Calendar
        @input="setStartDate"
        :max="endDate"
        />
      <span class="mr-1">→</span>
      <Calendar
        @input="setEndDate"
        />
      <button
        @click="searchResults"
        :disabled="searchDisabled"
        class="btn-bg ml-2">Search</button>
    </div>
    <div class="results">
      <table>
        <tr
          v-for="result in results"
          :class="{selected: isSelected(result)}"
          @click="selectResult(result)"
          :key="result.id">
          <td width="20%">{{result.testDate}}</td>
          <td v-if="isLesson">Lesson {{result.sort}}</td>
          <td v-else>Step {{result.step}}</td>
          <td>{{result.name}}</td>
          <td>{{result.score}}/{{result.totalScore}}</td>
        </tr>
      </table>
    </div>
    <button
      :disabled="!selectedResultId"
      class="btn-bg mt-2"
      @click="showTestResult">
      Details
    </button>
  </div>
</template>

<script>
import Calendar from '../../components/atom/Calendar.vue'

export default {
  name: 'LessonResults',
  components: {
    Calendar,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      selectedType: null,
      selectedResultId: null,
      results: [],
    }
  },
  created() {
    if(this.$route.query.type) this.showResultsPage(this.$route.query.type)
  },
  computed: {
    pageTitle() {
      if (this.selectedType == 'lesson') return 'Lesson Results'
      if (this.selectedType == 'trial') return 'Trial Examination'
      return ''
    },
    isSelected() {
      return result => result.testHistoryId === this.selectedResultId
    },
    isLesson() {
      return this.selectedType === 'lesson'
    },
    requestTestType() {
      return this.isLesson ? 'lesson' : 'trial,trial_reading,trial_listening'
    },
    searchDisabled() {
      return !this.startDate || !this.endDate || this.startDate.length != 10 || this.endDate.length != 10
    },
  },
  methods: {
    backToMenu() {
      this.$router.back()
    },
    backToList() {
      this.$router.back()
    },
    showResultsPage(type) {
      this.selectedType = type
      this.searchResults()
    },
    toResultsPage(type) {
      this.$router.push(`steps?redirectTo=lessonResults?type=${type}`)
    },
    startExam(id) {
      this.$router.push(`/lesson/${id}/exam`)
    },
    selectResult(result) {
      this.selectedResultId = result.testHistoryId
    },
    searchResults() {
      this.selectedResultId = null
      this.$api.post('/test/results', {
        step: this.$route.query.step,
        forms: this.$route.query.forms,
        type: this.requestTestType,
        startDate: this.startDate,
        endDate: this.endDate,
      }).then(res => {
        this.results = res.data.results
      })
    },
    setStartDate(v) {
      console.log('startDate:' + v)
      this.startDate = v
    },
    setEndDate(v) {
      this.endDate = v
    },
    showTestResult() {
      if (this.isLesson) {
        this.$router.push(`/result/lessonTest/${this.selectedResultId}/`)
      } else {
        this.$router.push(`/result/trialTest/${this.selectedResultId}/`)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.lessons {
  margin-top: 50px;
  .lesson-row {
    display: flex;
    .lesson {
      flex: 1;
      background: white;
      border-radius: 50px;
      margin: 40px 10px;
      padding: 30px 5px;
      cursor: pointer;
      border: 1px solid gray;
    }
  }
}
.minus-margin {
  margin-top: -40px;
}
.search-calendar {
  margin: auto;
  margin-top: 15px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.results {
  margin: auto;
  margin-top: 20px;
  width: 80%;
  overflow-y: scroll;
  height: 45vh;
  background: white;
  border-radius: 5px;
  padding: 5px 0px;
  box-shadow: 3px 6px 6px 1px rgb(80 80 80 / 20%);
  text-decoration: underline;
  color: #565258;
  table {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    border-collapse: collapse;
    td {
      border-bottom: 1px solid rgb(192, 186, 186);
      line-height: 250%;
    }
    tr:hover, .selected {
      background: rgb(243, 233, 233);
      cursor: pointer;
    }
  }
}
.menu-row {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 150px;
  img {
    width: 300px;
    cursor: pointer;
  }
}
</style>

